<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-col :span="12">
                    <el-form-item
                        prop="activityName"
                        label="活动名称"
                        label-width="8em"
                    >
                        <el-input
                            v-model="formModel.activityName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="activityTime"
                        label="活动时间"
                        label-width="8em"
                    >
                        <el-date-picker
                            :picker-options="$utils.CommonConfig.pickerOptions"
                            size="small"
                            v-model="formModel.activityTime"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="activityDesc"
                        label="活动描述"
                        label-width="8em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.activityDesc"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isAvailable"
                        label="是否可用"
                        label-width="8em"
                    >
                        <el-switch
                            v-model="formModel.isAvailable"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label="满额赠送配置"
                        label-width="8em"
                    >
                        <el-button
                            type="text"
                            size="small"
                            @click="onAddReduceAmountConfig"
                        >
                            新增规则
                        </el-button>
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="formModel.reduceAmountConfigList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="amount"
                                label="订单金额"
                                width="150"
                            >
                                <template slot-scope="scope">
                                    <el-form-item
                                        :prop="`reduceAmountConfigList[${scope.$index}].amount`"
                                        :rules="formRules.amount"
                                        label=""
                                    >
                                        <el-input-number
                                            controls-position="right"
                                            :min="1"
                                            :precision="0"
                                            v-model.number="formModel.reduceAmountConfigList[scope.$index].amount"
                                        />
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="giveDesc"
                                label="赠送说明"
                                width="300"
                            >
                                <template slot-scope="scope">
                                    <el-form-item
                                        :prop="`reduceAmountConfigList[${scope.$index}].giveDesc`"
                                        :rules="formRules.giveDesc"
                                        label=""
                                    >
                                        <el-input
                                            type="textarea"
                                            v-model="formModel.reduceAmountConfigList[scope.$index].giveDesc"
                                            auto-complete="off"
                                        />
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="操作"
                                width="60"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDelReduceAmountConfig(scope.row)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        prop="isLimitGoods"
                        label="是否限制商品"
                        label-width="8em"
                    >
                        <el-switch
                            v-model="formModel.isLimitGoods"
                        />
                    </el-form-item>
                    <el-form-item
                        label="商品列表"
                        label-width="8em"
                        v-if="formModel.isLimitGoods"
                    >
                        <div
                            class="ma-b"
                        >
                            <el-button-group>
                                <el-button
                                    type="success"
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="onAddSelectGoods"
                                >
                                    选择商品
                                </el-button>
                            </el-button-group>
                        </div>
                        <!-- 表格 -->
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="selectGoodsDataList"
                            style="width: 100%"
                        >
                            <!-- 商品规格 -->
                            <el-table-column type="expand">
                                <template slot-scope="goods">
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="goods.row.skuList"
                                        style="width: 100%"
                                    >
                                        <el-table-column
                                            prop="specDesc"
                                            label="商品规格"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="price"
                                            label="价格"
                                            min-width="50"
                                        />
                                        <el-table-column
                                            prop="stock"
                                            label="库存"
                                            min-width="50"
                                        />
                                    </el-table>
                                </template>
                            </el-table-column>
                            <!--<el-table-column
    label="商品图片"
    width="120"
>
    <template slot-scope="scope">
        <ImageList
            :data="scope.row.mainImageUrl"
        />
    </template>
</el-table-column>-->
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="goodsSn"
                                label="商品编号"
                                min-width="100"
                            />
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="45"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDeleteSelectGoods(scope.row)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label-width="8em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row>

        <!-- 选择商品 -->
        <el-dialog
            title="选择商品"
            center
            width="1200px"
            :visible.sync="selectGoodsDialog.isVisible"
            @closed="onSelectGoodsCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectGoodsDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectGoodsDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="selectGoodsDialog.queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    label="商品编号"
                >
                    <el-input
                        v-model="selectGoodsDialog.queryFormModel.goodsSn"
                        placeholder="请输入商品编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopTagIdList"
                    label="商品分组"
                >
                    <RemoteSelect
                        multiple
                        remote="/sp/shopTag/select"
                        placeholder="请输入"
                        :props="{id:'id',name:'tagName'}"
                        v-model="selectGoodsDialog.queryFormModel.shopTagIdList"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectGoodsDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectGoodsDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectGoodsDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="goodsId"
                    ref="selectGoodsDialogTable"
                    @selection-change="onSelectGoodsDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="mainImageUrl"
                        label="商品图片"
                        width="135"
                    >
                        <template slot-scope="scope">
                            <image-list :data="scope.row.mainImageUrl"/>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsName"
                        label="商品名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        label="商品编号"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectGoodsDialog.pagination"
                        @input="onSelectGoodsDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectGoodsConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectGoodsCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'FulfilGiveFullGiveEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                fulfilGiveType: 3,
                activityName: '',
                activityTime: [],
                activityDesc: '',
                isLimitGoods: false,
                isAvailable: true,

                reduceAmountConfigList: [],
            },
            // 表单校验规则
            formRules: {
                activityName: {
                    required: true,
                    message: '请输入活动名称',
                    trigger: 'blur',
                },
                activityBeginTime: {
                    required: true,
                    message: '请选择活动开始时间',
                },
                activityEndTime: {
                    required: true,
                    message: '请选择活动结束时间',
                },
                activityTime: {
                    type: 'array',
                    required: true,
                    message: '请选择活动时间',
                },
                amount: {
                    required: true,
                    message: '请输入金额',
                    trigger: 'blur',
                },
                reduceAmount: {
                    required: true,
                    message: '请输入金额',
                    trigger: 'blur',
                },
                giveDesc: {
                    required: true,
                    message: '请输入赠送说明',
                    trigger: 'blur',
                },
            },
            // 商品弹窗
            selectGoodsDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                    goodsSn: '', // 商品编号
                    shopTagIdList: [],
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 已选择数据
                currentTableSelect: [],
            },
            // 商品表格数据
            selectGoodsDataList: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Pm.FulfilGive.save({
                    ...this.formModel,
                    reduceAmountConfig: JSON.stringify(this.formModel.reduceAmountConfigList),
                    goodsListJson: JSON.stringify(this.selectGoodsDataList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Pm.FulfilGive.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res.fulfilGive);
                if (res.fulfilGive.reduceAmountConfig) {
                    this.formModel.reduceAmountConfigList = JSON.parse(res.fulfilGive.reduceAmountConfig);
                }
                this.selectGoodsDataList = res.goodsList;
            });
        },
        onAddReduceAmountConfig() {
            this.formModel.reduceAmountConfigList.push({});
        },
        onDelReduceAmountConfig(row) {
            this.formModel.reduceAmountConfigList.splice(this.formModel.reduceAmountConfigList.indexOf(row), 1);
        },
        // 选择商品
        onAddSelectGoods() {
            this.selectGoodsDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectGoodsDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectGoodsDialogTable) {
                    this.$refs.selectGoodsDialogTable.clearSelection();
                }
                this.selectGoodsDataList.forEach(item => {
                    this.$refs.selectGoodsDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectGoodsDialogQuery(pagination) {
            return this.$api.Gd.Goods.selectByPage({
                ...this.selectGoodsDialog.queryFormModel,
                ...pagination,
                isCombinationGoods: false,
            }).then(json => {
                const res = json.data;
                this.selectGoodsDialog.tableData = res.data;
                this.selectGoodsDialog.pagination = res.pagination;
            });
        },
        onSelectGoodsDialogSelectionChange(val) {
            this.selectGoodsDialog.currentTableSelect = val;
        },
        onResetSelectGoodsDialog() {
            this.$refs.selectGoodsDialogQueryForm.resetFields();
        },
        onSelectGoodsConfirm() {
            // 插入新数据
            const list = [];
            this.selectGoodsDialog.currentTableSelect.forEach(item => {
                let isHave = false;
                this.selectGoodsDataList.forEach(oldItem => {
                    if (item.goodsId === oldItem.goodsId) {
                        list.push({ ...oldItem });
                        isHave = true;
                    }
                });
                if (!isHave) {
                    // 添加新数据
                    item.id = null;
                    list.push({ ...item });
                }
            });
            this.selectGoodsDataList = [...list];
            this.selectGoodsDialog.isVisible = false;
        },
        onSelectGoodsCancel() {
            this.selectGoodsDialog.isVisible = false;
        },
        onDeleteSelectGoods(row) {
            this.selectGoodsDataList.splice(this.selectGoodsDataList.indexOf(row), 1);
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
